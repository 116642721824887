// src/styles/theme.js
import { styled, createGlobalStyle } from 'styled-components';


export const theme = {
    colors: {
        gold: '#C5A572',
        white: '#FFFFFF',
        black: '#000000',
        gray: {
            100: '#F7F7F7',
            200: '#E5E5E5',
            300: '#D4D4D4',
            400: '#A3A3A3',
            500: '#737373',
            600: '#525252',
            700: '#404040',
            800: '#262626',
            900: '#171717',
        }
    },
    typography: {
        fontFamily: {
            primary: "'Helvetica Neue', sans-serif",
            secondary: "'Georgia', serif"
        },
        fontSize: {
            xs: '0.75rem',
            sm: '0.875rem',
            base: '1rem',
            lg: '1.125rem',
            xl: '1.25rem',
            '2xl': '1.5rem',
            '3xl': '1.875rem',
            '4xl': '2.25rem',
        },
    },
    spacing: {
        none: '0',
        xs: '0.25rem',
        sm: '0.5rem',
        md: '1rem',
        lg: '1.5rem',
        xl: '2rem',
        '2xl': '3rem',
        '3xl': '4rem',
    },
    transitions: {
        default: '0.3s ease-in-out',
        slow: '0.7s ease-in-out',
        fast: '0.15s ease-in-out',
    },
    breakpoints: {
        xs: '320px',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
    }
};

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: ${props => props.theme.typography.fontFamily.primary};
    line-height: 1.5;
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
  }

  // Timeline-style content layout
  .timeline-item {
    position: relative;
    padding: ${props => props.theme.spacing.xl} 0;
    
    &.with-line::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      background-color: ${props => props.theme.colors.gold};
      transform: translateX(-50%);
    }
  }

  // Text alignment utilities
  .text-center { text-align: center; }
  .text-gold { color: ${props => props.theme.colors.gold}; }

  // Animation classes
  .fadeIn {
    opacity: 0;
    animation: fadeIn 0.7s ease-in-out forwards;
  }

  .seen {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.7s ease-in-out forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

// Common styled components
export const Button = styled.button`
  position: relative;
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.xl};
  font-size: ${props => props.theme.typography.fontSize.base};
  border: 1px solid;
  background: transparent;
  cursor: pointer;
  transition: all ${props => props.theme.transitions.default};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  overflow: hidden;

  &.button-gold {
    color: ${props => props.theme.colors.gold};
    border-color: ${props => props.theme.colors.gold};
    
    &:hover {
      background-color: ${props => props.theme.colors.gold};
      color: ${props => props.theme.colors.black};
    }
  }

  &.button-white {
    color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.white};
    
    &:hover {
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.black};
    }
  }

  &.button-large {
    padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing['2xl']};
    font-size: ${props => props.theme.typography.fontSize.lg};
  }
`;

export const TitleBlock = styled.div`
  margin-bottom: ${props => props.theme.spacing.xl};

  .category {
    margin-bottom: ${props => props.theme.spacing.sm};
    
    h2 {
      display: flex;
      align-items: center;
      font-size: ${props => props.theme.typography.fontSize.sm};
      text-transform: uppercase;
      letter-spacing: 0.1em;

      .line-container {
        margin-left: ${props => props.theme.spacing.sm};
        flex: 1;
        height: 1px;
        
        .line {
          display: block;
          width: 100%;
          height: 1px;
          background-color: currentColor;
        }
      }
    }
  }

  h3 {
    font-size: ${props => props.theme.typography.fontSize['3xl']};
    margin-bottom: ${props => props.theme.spacing.lg};
    font-family: ${props => props.theme.typography.fontFamily.secondary};
  }
`;

export default theme;