import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const BagSection = styled.section`
  position: relative;
  // background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing['3xl']} 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background: ${props => props.theme.colors.gold};
    
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: ${props => props.theme.spacing.xl};
    }
  }
`;

const BagHeader = styled.div`
  padding: 0 ${props => props.theme.spacing['2xl']};
  margin-bottom: ${props => props.theme.spacing['3xl']};
  position: relative;
  z-index: 1;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 ${props => props.theme.spacing.xl};
    margin-left: ${props => props.theme.spacing.xl};
  }
`;

const HeaderDate = styled(motion.div)`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.sm};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: ${props => props.theme.spacing.md};
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const HeaderTitle = styled(motion.h2)`
  font-size: clamp(3rem, 8vw, 5rem);
  line-height: 0.9;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
`;

const BagContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const EquipmentCard = styled(motion.div)`
  width: 45%;
  position: relative;
  background: ${props => props.theme.colors.gray[800]};
  padding: ${props => props.theme.spacing.xl};
  border-radius: ${props => props.theme.spacing.sm};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: ${props => props.theme.spacing.xl};
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
  }
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    ${props => props.isRight ? `
      margin-left: 55%;
      &::before {
        right: 100%;
      }
    ` : `
      margin-right: 55%;
      &::before {
        left: 100%;
      }
    `}
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    
    &::before {
      left: -${props => props.theme.spacing.xl};
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 4/3;
  border-radius: ${props => props.theme.spacing.sm};
  overflow: hidden;
  margin-bottom: ${props => props.theme.spacing.lg};
`;

const BagImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;

  ${EquipmentCard}:hover & {
    transform: scale(1.05);
  }
`;

const BagTitle = styled.h3`
  font-size: ${props => props.theme.typography.fontSize.xl};
  font-weight: 600;
  color: ${props => props.theme.colors.gold};
  margin-bottom: ${props => props.theme.spacing.md};
`;

const SpecList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const SpecItem = styled.li`
  font-size: ${props => props.theme.typography.fontSize.base};
  color: ${props => props.theme.colors.gray[200]};
  margin-bottom: ${props => props.theme.spacing.xs};
  line-height: 1.6;
  
  strong {
    color: ${props => props.theme.colors.gold};
  }
`;

// Single equipment card component for proper hook usage
const EquipmentCardComponent = ({ item, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <EquipmentCard
      ref={ref}
      isRight={index % 2 === 1}
      initial={{ opacity: 0, x: index % 2 === 0 ? -30 : 30 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.7, delay: index * 0.1 }}
    >
      <ImageContainer>
        <BagImage src={item.image} alt={item.title} />
      </ImageContainer>
      <BagTitle>{item.title}</BagTitle>
      <SpecList>
        {item.specs.map((spec, index) => (
          <SpecItem key={index}>
            <strong>{spec.label}:</strong> {spec.value}
          </SpecItem>
        ))}
      </SpecList>
    </EquipmentCard>
  );
};

function Bag() {
  const [headerRef, headerInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const data = [
    {
      title: "Driver",
      image: "ping-g430-10k-max-golf-driver-hero.jpg",
      specs: [
        { label: "Head", value: "PING G430 9 degree MAX Driver" },
        { label: "Shaft", value: "Fujikjura Vista PRO 2021 55 Stiff" },
        { label: "Grip", value: "Golf Pride MCC Teams Grey/Red" }
      ]
    },
    {
      title: "Hybrid",
      image: "pingg430hybridmain.jpg",
      specs: [
        { label: "Head", value: "PING G430 3-19" },
        { label: "Shaft", value: "Accra FX Gen 3 - 200H M4" },
        { label: "Grip", value: "Golf Pride MCC Teams Grey/Red" }
      ]
    },
    {
      title: "Irons",
      image: "Mizuno_Pro_223_Golf_Irons_Steel_5_1800x1800.webp",
      specs: [
        { label: "Head", value: "Mizuno Pro 223 4-PW" },
        { label: "Shaft", value: "Accra iSeries Steel Green 115g" },
        { label: "Grip", value: "Golf Pride MCC Teams Blue/Red" }
      ]
    },
    {
      title: "Wedges",
      image: "Titleist_Vokey_SM9_Golf_Wedge_Jet_Black_57_1080x.webp",
      specs: [
        { label: "Head", value: "Titleist Vokey SM9 Jet Black 52/58" },
        { label: "Shaft", value: "Accra FX Gen 3 - 200H M4" },
        { label: "Grip", value: "Golf Pride MCC Teams Blue/Red" }
      ]
    },
    {
      title: "Putter",
      image: "lab_link.jpeg",
      specs: [
        { label: "Head", value: "L.A.B Link 1" },
        { label: "Shaft", value: "L.A.B Stability" },
        { label: "Grip", value: "L.A.B Press Grip" }
      ]
    },
    {
      title: "Ball",
      image: "prov1.jpeg",
      specs: [
        { label: "Model", value: "Titleist PRO V1" }
      ]
    }
  ];

  return (
    <BagSection>
      <BagHeader ref={headerRef}>
        <HeaderDate
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          Current Setup
        </HeaderDate>

        <TitleWrapper>
          <HeaderTitle
            initial={{ y: "100%" }}
            animate={headerInView ? { y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Equipment
          </HeaderTitle>
        </TitleWrapper>
      </BagHeader>

      <BagContainer>
        {data.map((item, index) => (
          <EquipmentCardComponent
            key={item.title}
            item={item}
            index={index}
          />
        ))}
      </BagContainer>
    </BagSection>
  );
}

export default Bag;


