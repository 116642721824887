import React, { lazy, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import LoadingScreen from "./components/LoadingScreen";
import "./App.css";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Bag from "./components/Bag";
import Stat from "./components/Stat";
import Stats from "./components/Stats";
import Sponsors from "./components/Sponsors";
import styled from "styled-components";
import Tournaments from "./components/Tournaments";
import AdminDashboard from "./components/AdminDashboard";
import useGlobalAnimations from "./components/useGlobalAnimations";
import Login from "./components/Login";
import HandicapTracker from "./components/HandicapTracker";
import NewsSlider from "./components/NewsSlider";
import InstagramCarousel from "./components/InstagramCarousel";
import { ThemeProvider } from 'styled-components';
import { theme } from './Theme';
import HeroGallery from "./components/HeroGallery";

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background-image: url('/GRKcLagWMAAV7Jd.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.85) 50%,
      rgba(0, 0, 0, 0.95) 100%
    );
    backdrop-filter: blur(5px);
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  z-index: 1;
`;


const Scores = lazy(() => import("./components/Scores"));

function App() {
  useGlobalAnimations();
  const [scores, setScores] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fadeOutLoading, setFadeOutLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed in App:", user);
      setUser(user);
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleLoad = () => setFadeOutLoading(true);
    window.addEventListener("load", handleLoad);

    const loadTimeout = setTimeout(() => {
      setFadeOutLoading(true);
    }, 5000);

    return () => {
      window.removeEventListener("load", handleLoad);
      clearTimeout(loadTimeout);
    };
  }, []);

  useEffect(() => {
    if (fadeOutLoading) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [fadeOutLoading]);

  const addScore = (newScore) => {
    setScores([...scores, newScore]);
  };

  const ProtectedRoute = ({ children }) => {
    if (isLoading) {
      return <LoadingScreen fadeOut={fadeOutLoading} />;
    }
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  if (isLoading) {
    return <LoadingScreen fadeOut={fadeOutLoading} />;
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <BackgroundContainer />
        <Suspense fallback={<LoadingScreen fadeOut={fadeOutLoading} />}>
          <ContentWrapper style={{ display: isLoading ? "none" : "block" }}>
            <Navbar isAuthenticated={isAuthenticated} />
            {error && <div className="error">{error}</div>}
            <Routes>
              <Route
                path="/login"
                element={
                  isAuthenticated ? (
                    <Navigate to="/admin" />
                  ) : (
                    <Login setIsAuthenticated={setIsAuthenticated} />
                  )
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <AdminDashboard
                      user={user}
                      scores={scores}
                      setScores={setScores}
                      addScore={addScore}
                      setIsAuthenticated={setIsAuthenticated}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <>
                    <HeroGallery />
                    <div id="about">
                      <About />
                    </div>
                    <div id="news">
                      <NewsSlider />
                    </div>
                    <div id="bag">
                      <Bag />
                    </div>
                    <div id="handicap">
                      <HandicapTracker />
                    </div>
                    <div id="scores">
                      <Scores scores={scores} />
                    </div>
                    <div id="sponsors">
                      <Sponsors />
                    </div>
                    <div id="tournaments">
                      <Tournaments />
                    </div>
                    <div id="stats">
                      <Stat />
                    </div>
                  </>
                }
              />
            </Routes>
          </ContentWrapper>
        </Suspense>
      </ThemeProvider>
    </Router>
  );
}

export default App;
