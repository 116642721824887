import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import client from "./contentful";

const SponsorsSection = styled.section`
  position: relative;
  // background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing['3xl']} 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background: ${props => props.theme.colors.gold};
    
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: ${props => props.theme.spacing.xl};
    }
  }
`;

const SponsorsHeader = styled.div`
  padding: 0 ${props => props.theme.spacing['2xl']};
  margin-bottom: ${props => props.theme.spacing['3xl']};
  position: relative;
  z-index: 1;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 ${props => props.theme.spacing.xl};
    margin-left: ${props => props.theme.spacing.xl};
  }
`;

const HeaderDate = styled(motion.div)`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.sm};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: ${props => props.theme.spacing.md};
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const HeaderTitle = styled(motion.h2)`
  font-size: clamp(3rem, 8vw, 5rem);
  line-height: 0.9;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
`;

const SponsorsContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.xl};
`;

const SponsorCard = styled(motion.div)`
  width: 45%;
  position: relative;
  // background: ${props => props.theme.colors.gray[900]};
  padding: ${props => props.theme.spacing.xl};
  border-radius: ${props => props.theme.spacing.sm};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: ${props => props.theme.spacing.xl};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.lg};
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
  }
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    ${props => props.isRight ? `
      margin-left: 55%;
      &::before {
        right: 100%;
      }
    ` : `
      margin-right: 55%;
      &::before {
        left: 100%;
      }
    `}
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    
    &::before {
      left: -${props => props.theme.spacing.xl};
    }
  }
`;

const SponsorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.md};
`;

const SponsorName = styled.h3`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.xl};
  font-weight: 600;
  margin: 0;
`;

const SponsorDescription = styled.p`
  color: ${props => props.theme.colors.gray[200]};
  font-size: ${props => props.theme.typography.fontSize.base};
  line-height: 1.6;
  margin: 0;
`;

const LogoContainer = styled.div`
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: ${props => props.theme.colors.gray[800]};
  border-radius: ${props => props.theme.spacing.sm};
  padding: ${props => props.theme.spacing.lg};
`;

const SponsorLink = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform ${props => props.theme.transitions.default};

  &:hover {
    transform: scale(1.05);
  }
`;

const SponsorImage = styled.img`
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  transition: all ${props => props.theme.transitions.default};
  filter: brightness(0) invert(1) opacity(0.8);

  ${SponsorLink}:hover & {
    filter: brightness(0) invert(1);
  }
`;

// Update SponsorCardComponent
const SponsorCardComponent = ({ logo, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <SponsorCard
      ref={ref}
      isRight={index % 2 === 1}
      initial={{ opacity: 0, x: index % 2 === 0 ? -30 : 30 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.7, delay: index * 0.1 }}
    >
      <LogoContainer>
        <SponsorLink
          href={logo.link}
          target="_blank"
          rel="noopener noreferrer"
          title={logo.title}
        >
          <SponsorImage src={logo.image} alt={logo.title} />
        </SponsorLink>
      </LogoContainer>
      <SponsorContent>
        <SponsorName>{logo.title}</SponsorName>
        <SponsorDescription>{logo.description}</SponsorDescription>
      </SponsorContent>
    </SponsorCard>
  );
};





function Sponsors() {
  const [logos, setLogos] = useState([]);
  const [headerRef, headerInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: "sponsors",
        });

        const fetchedLogos = response.items.map((item) => ({
          title: item.fields.sponsorName,
          link: item.fields.sponsorLink,
          image: item.fields.sponsorImage.fields.file.url,
          description: item.fields.sponsorDescription || '', // New field
        }));

        setLogos(fetchedLogos);
      } catch (error) {
        console.error("Error fetching Sponsors:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <SponsorsSection>
      <SponsorsHeader ref={headerRef}>
        <HeaderDate
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          Support
        </HeaderDate>

        <TitleWrapper>
          <HeaderTitle
            initial={{ y: "100%" }}
            animate={headerInView ? { y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Sponsors
          </HeaderTitle>
        </TitleWrapper>
      </SponsorsHeader>

      <SponsorsContainer>
        {logos.map((logo, index) => (
          <SponsorCardComponent
            key={index}
            logo={logo}
            index={index}
          />
        ))}
      </SponsorsContainer>
    </SponsorsSection>
  );
}

export default Sponsors;