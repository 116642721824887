import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Instagram, Twitter, Youtube, Mail } from 'lucide-react';


const SocialLinks = styled.div`
  position: fixed;
  top: 30px;
  right: ${props => props.theme.spacing.xl};
  display: none;
  align-items: center;
  gap: ${props => props.theme.spacing.md};
  z-index: 101;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`;

const SocialIcon = styled.a`
  color: ${props => props.theme.colors.white};
  opacity: 0.8;
  transition: ${props => props.theme.transitions.default};
  display: flex;
  align-items: center;
  
  &:hover {
    opacity: 1;
    color: ${props => props.theme.colors.gold};
    transform: translateY(-2px);
  }
`;

const MobileSocialLinks = styled.div`
  margin-top: ${props => props.theme.spacing['2xl']};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing.lg};
`;

const MobileEmail = styled.a`
  color: ${props => props.theme.colors.gold};
  text-decoration: none;
  font-size: ${props => props.theme.typography.fontSize.base};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.sm};
  margin-bottom: ${props => props.theme.spacing.md};
  
  &:hover {
    opacity: 0.8;
  }
`;

const MobileSocialIconsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.xl};

  a {
    color: ${props => props.theme.colors.white};
    opacity: 0.8;
    transition: ${props => props.theme.transitions.default};
    
    &:hover {
      opacity: 1;
      color: ${props => props.theme.colors.gold};
    }
  }
`;



const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${props => props.theme.spacing.xl};
  background: transparent;
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding-left: 10px;
  }
`;

const LogoContainer = styled.div`
  h1 {
    margin: 0;
    img {
      height: 60px;
    }
  }
`;

const SideNav = styled.nav`
  position: fixed;
  left: 0;
  top: 120px;
  display: none;
  flex-direction: column;
  gap: ${props => props.theme.spacing.sm};
  z-index: 98;
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`;


const MobileMenuButton = styled.div`
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
  
  .line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.white};
    transition: ${props => props.theme.transitions.default};
    
    &:nth-child(1) { top: 0; }
    &:nth-child(2) { top: 50%; }
    &:nth-child(3) { top: 100%; }
  }
  
  ${props => props.isOpen && `
    .line {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 50%;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 50%;
      }
    }
  `}
`;

const MobileNav = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 97;
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const MobileNavLinks = styled.div`
  text-align: center;
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin: ${props => props.theme.spacing.lg} 0;
  }
`;

const NavLink = styled.a`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  font-size: ${props => props.theme.typography.fontSize.xl};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: ${props => props.theme.transitions.default};
  
  &:hover {
    color: ${props => props.theme.colors.gold};
  }
`;

const TabButton = styled.button`
  position: relative;
  height: 40px;
  background: ${props => props.theme.colors.white};
  border: none;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  width: ${props => props.isExpanded ? `${props.expandedWidth}px` : '15px'};
  transition: width ${props => props.theme.transitions.default};
  
  span {
    position: absolute;
    left: ${props => props.theme.spacing.md};
    top: 50%;
    transform: translateY(-50%);
    color: ${props => props.theme.colors.black};
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: ${props => props.theme.typography.fontSize.sm};
    white-space: nowrap;
    opacity: ${props => props.isExpanded ? 1 : 0};
    transition: opacity ${props => props.theme.transitions.default};
  }
  
  &:hover {
    width: ${props => props.expandedWidth}px;
    
    span {
      opacity: 1;
    }
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [textWidths, setTextWidths] = useState({});
  const [expandedTab, setExpandedTab] = useState(null);

  const navItems = [
    { id: 'about', label: 'About', href: '#about' },
    { id: 'equipment', label: 'Equipment', href: '#bag' },
    { id: 'sponsors', label: 'Sponsors', href: '#sponsors' },
    { id: 'tournaments', label: 'Tournaments', href: '#tournaments' },
    { id: 'stats', label: 'Stats', href: '#stats' }
  ];

  // Calculate text widths on mount
  useEffect(() => {
    const tempSpan = document.createElement('span');
    tempSpan.style.visibility = 'hidden';
    tempSpan.style.position = 'absolute';
    tempSpan.style.fontSize = '0.875rem';
    tempSpan.style.letterSpacing = '0.1em';
    tempSpan.style.textTransform = 'uppercase';
    document.body.appendChild(tempSpan);

    const widths = {};
    navItems.forEach(item => {
      tempSpan.textContent = item.label;
      widths[item.id] = tempSpan.getBoundingClientRect().width + 52;
    });

    document.body.removeChild(tempSpan);
    setTextWidths(widths);
  }, []);

  // Handle scroll position
  useEffect(() => {
    const handleScroll = () => {
      const headerOffset = 80; // Match the header height offset
      const scrollPosition = window.scrollY + headerOffset;

      navItems.forEach(item => {
        const section = document.querySelector(item.href);
        if (section) {
          const sectionTop = section.offsetTop;
          const sectionBottom = sectionTop + section.offsetHeight;

          // Check if the section's top is at the viewport top (accounting for header)
          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            setExpandedTab(item.id);
            // Auto collapse after 2 seconds
            setTimeout(() => {
              setExpandedTab(null);
            }, 2000);
          }
        }
      });
    };

    // Throttle the scroll event to improve performance
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.querySelector(sectionId);
    if (section) {
      const offset = 80;
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      // Expand the clicked tab
      setExpandedTab(sectionId.replace('#', ''));

      // Smooth scroll with custom duration
      const duration = 1500;
      const startPosition = window.pageYOffset;
      const distance = offsetPosition - startPosition;
      let startTime = null;

      const animation = currentTime => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);

        const ease = t => t * (2 - t);
        window.scrollTo(0, startPosition + (distance * ease(progress)));

        if (timeElapsed < duration) {
          requestAnimationFrame(animation);
        }
      };

      requestAnimationFrame(animation);

      // Collapse the tab after 2 seconds
      setTimeout(() => {
        setExpandedTab(null);
      }, 2000);

      setActiveTab(sectionId.replace('#', ''));
      setIsOpen(false);
    }
  };

  return (
    <>
      <Header>
        <LogoContainer>
          <h1>
            <img src="/CLG.png" alt="Cameron Lowe Golf" />
          </h1>
        </LogoContainer>
        <SocialLinks>
          <SocialIcon
            href="mailto:contact@cameronlowegolf.com"
            aria-label="Email"
          >
            <Mail size={20} />
          </SocialIcon>
          <SocialIcon
            href="https://www.instagram.com/camlowegolf/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <Instagram size={20} />
          </SocialIcon>
          <SocialIcon
            href="https://twitter.com/cameronlowegolf"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <Twitter size={20} />
          </SocialIcon>
          <SocialIcon
            href="https://www.youtube.com/channel/UC1QY6uVH3w5Z0t8xHJxXs1Q"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <Youtube size={20} />
          </SocialIcon>
        </SocialLinks>
        <MobileMenuButton
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="line" />
          <span className="line" />
          <span className="line" />
        </MobileMenuButton>
      </Header>

      <SideNav>
        {navItems.map(item => (
          <TabButton
            key={item.id}
            isExpanded={expandedTab === item.id}
            expandedWidth={textWidths[item.id]}
            onClick={(e) => scrollToSection(e, item.href)}
          >
            <span>{item.label}</span>
          </TabButton>
        ))}
      </SideNav>

      <AnimatePresence>
        {isOpen && (
          <MobileNav
            initial={{ opacity: 0, x: '-100%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: '-100%' }}
            transition={{ duration: 0.3 }}
          >
            <MobileNavLinks>
              <ul>
                {navItems.map(item => (
                  <li key={item.id}>
                    <NavLink
                      href={item.href}
                      onClick={(e) => scrollToSection(e, item.href)}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
              <MobileSocialLinks>
                <MobileEmail href="mailto:contact@cameronlowegolf.com">
                  <Mail size={16} />
                  contact@cameronlowegolf.com
                </MobileEmail>
                <MobileSocialIconsContainer>
                  <SocialIcon
                    href="https://www.instagram.com/camlowegolf/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <Instagram size={24} />
                  </SocialIcon>
                  <SocialIcon
                    href="https://twitter.com/cameronlowegolf"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter"
                  >
                    <Twitter size={24} />
                  </SocialIcon>
                  <SocialIcon
                    href="https://www.youtube.com/channel/UC1QY6uVH3w5Z0t8xHJxXs1Q"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="YouTube"
                  >
                    <Youtube size={24} />
                  </SocialIcon>
                </MobileSocialIconsContainer>
              </MobileSocialLinks>
            </MobileNavLinks>
          </MobileNav>
        )}
      </AnimatePresence>
    </>
  );
}

export default Navbar;