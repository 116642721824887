import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import client from "./contentful";
import { ChevronRight } from "lucide-react";

const SectionWrapper = styled.section`
  position: relative;
  // background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing['3xl']} 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background: ${props => props.theme.colors.gold};
    
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: ${props => props.theme.spacing.xl};
    }
  }
`;


const NewsHeader = styled.div`
  padding: 0 ${props => props.theme.spacing['2xl']};
  margin-bottom: ${props => props.theme.spacing['3xl']};
  position: relative;
  z-index: 1;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 ${props => props.theme.spacing.xl};
    margin-left: ${props => props.theme.spacing.xl};
  }
`;

const HeaderDate = styled(motion.div)`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.sm};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: ${props => props.theme.spacing.md};
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const HeaderTitle = styled(motion.h2)`
  font-size: clamp(3rem, 8vw, 5rem);
  line-height: 0.9;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
`;

const NewsContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  padding: ${props => props.theme.spacing.xl};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NewsItem = styled(motion.div)`
  width: 45%;
  position: relative;
  background: ${props => props.theme.colors.gray[800]};
  padding: ${props => props.theme.spacing.xl};
  border-radius: ${props => props.theme.spacing.sm};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: ${props => props.theme.spacing.xl};
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
  }
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    ${props => props.isRight ? `
      margin-left: 55%;
      &::before {
        right: 100%;
      }
    ` : `
      margin-right: 55%;
      &::before {
        left: 100%;
      }
    `}
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    
    &::before {
      left: -${props => props.theme.spacing.xl};
    }
  }
`;

const NewsTitle = styled.h3`
  font-size: ${props => props.theme.typography.fontSize.xl};
  font-weight: 600;
  color: ${props => props.theme.colors.gold};
  margin-bottom: ${props => props.theme.spacing.md};
`;

const NewsText = styled.p`
  font-size: ${props => props.theme.typography.fontSize.base};
  line-height: 1.6;
  color: ${props => props.theme.colors.gray[200]};
  margin-bottom: ${props => props.theme.spacing.lg};
`;

const NewsLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: ${props => props.theme.spacing.xs};
  color: ${props => props.theme.colors.gold};
  text-decoration: none;
  font-size: ${props => props.theme.typography.fontSize.base};
  transition: all ${props => props.theme.transitions.default};
  
  &:hover {
    gap: ${props => props.theme.spacing.sm};
    opacity: 0.8;
  }
`;

const ExpandButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.spacing.xs};
  background: ${props => props.theme.colors.gold};
  color: ${props => props.theme.colors.black};
  padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing['2xl']};
  border: none;
  border-radius: ${props => props.theme.spacing.sm};
  cursor: pointer;
  font-size: ${props => props.theme.typography.fontSize.base};
  font-weight: 500;
  margin-top: ${props => props.theme.spacing['2xl']};
  position: relative;
  z-index: 2;
  transition: all ${props => props.theme.transitions.default};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(197, 165, 114, 0.2);
  }

  svg {
    transition: transform ${props => props.theme.transitions.default};
  }
`;

const LoadingMessage = styled.div`
  color: ${props => props.theme.colors.gold};
  text-align: center;
  padding: ${props => props.theme.spacing.xl};
  font-size: ${props => props.theme.typography.fontSize.lg};
`;

// Separate NewsItem component
const NewsItemComponent = ({ newsItem, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <NewsItem
      ref={ref}
      isRight={index % 2 === 1}
      initial={{ opacity: 0, x: index % 2 === 0 ? -30 : 30 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.7, delay: index * 0.2 }}
    >
      <NewsTitle>{newsItem.fields.newsHeading}</NewsTitle>
      <NewsText>{newsItem.fields.newsText}</NewsText>
      {newsItem.fields.newsLink && (
        <NewsLink
          href={newsItem.fields.newsLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more
          <ChevronRight size={16} />
        </NewsLink>
      )}
    </NewsItem>
  );
};

const NewsSection = () => {
  const [news, setNews] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [headerRef, headerInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const fetchNews = useCallback(async () => {
    try {
      const response = await client.getEntries({
        content_type: "news",
        order: "-sys.createdAt",
        limit: 5,
      });
      setNews(response.items);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news:", error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  if (isLoading) {
    return (
      <SectionWrapper>
        <LoadingMessage>Loading latest news...</LoadingMessage>
      </SectionWrapper>
    );
  }

  const displayedNews = isExpanded ? news : news.slice(0, 2);

  return (
    <SectionWrapper>
      <NewsHeader ref={headerRef}>
        <HeaderDate
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          Latest Updates
        </HeaderDate>

        <TitleWrapper>
          <HeaderTitle
            initial={{ y: "100%" }}
            animate={headerInView ? { y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            News
          </HeaderTitle>
        </TitleWrapper>
      </NewsHeader>

      <NewsContainer>
        {displayedNews.map((newsItem, index) => (
          <NewsItemComponent
            key={newsItem.sys.id}
            newsItem={newsItem}
            index={index}
          />
        ))}

        {news.length > 2 && (
          <ExpandButton
            onClick={() => setIsExpanded(!isExpanded)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
            <ChevronRight
              size={16}
              style={{
                transform: isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)',
                transition: 'transform 0.3s ease'
              }}
            />
          </ExpandButton>
        )}
      </NewsContainer>
    </SectionWrapper>
  );
};

export default NewsSection;