import React, { useEffect, useState } from "react";
import styled from "styled-components";
import client from "./contentful";
import InfiniteScroll from "react-infinite-scroll-component";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import theme from "../Theme";

const HeroSection = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
//   background: ${props => props.theme.colors.black};
`;

const HeroContent = styled.div`
  position: relative;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${props => props.theme.spacing['3xl']};
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 50vh;
    padding: ${props => props.theme.spacing.xl};
  }
`;

const MainHeading = styled(motion.h1)`
  color: ${props => props.theme.colors.white};
  font-size: clamp(3rem, 10vw, 8rem);
  line-height: 0.9;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  font-family: ${props => props.theme.typography.fontFamily.primary};
`;

const SubHeading = styled(motion.div)`
  color: ${props => props.theme.colors.gold};
  font-size: clamp(1rem, 3vw, 2rem);
  margin-top: ${props => props.theme.spacing.md};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
`;

const TimelineContainer = styled.div`
  position: relative;
  padding: ${props => props.theme.spacing['2xl']} 0 ${props => props.theme.spacing['3xl']} 0;
  max-width: 1400px;
  margin: 0 auto;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background-color: ${props => props.theme.colors.gold};
    
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: ${props => props.theme.spacing.xl};
    }
  }
`;

const Loading = styled.p`
  color: ${props => props.theme.colors.white};
  text-align: center;
  margin-top: ${props => props.theme.spacing.xl};
`;

const TimelineItem = styled(motion.div)`
  position: relative;
  margin: ${props => props.theme.spacing.xl} 0;
  width: 45%;
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    ${props => props.isRight ? `
      margin-left: 55%;
      
      &::before {
        content: '';
        position: absolute;
        left: -${props.theme.spacing.xl};
        top: 50%;
        width: ${props.theme.spacing.xl};
        height: 1px;
        background-color: ${props.theme.colors.gold};
      }
    ` : `
      margin-right: 55%;
      
      &::before {
        content: '';
        position: absolute;
        right: -${props.theme.spacing.xl};
        top: 50%;
        width: ${props.theme.spacing.xl};
        height: 1px;
        background-color: ${props.theme.colors.gold};
      }
    `}
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    
    &::before {
      content: '';
      position: absolute;
      left: -${props => props.theme.spacing.xl};
      top: 50%;
      width: ${props => props.theme.spacing.xl};
      height: 1px;
      background-color: ${props => props.theme.colors.gold};
    }
  }
`;

const ImageContainer = styled(motion.div)`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${props => props.theme.spacing.sm};
  aspect-ratio: 4/3;
  background: ${props => props.theme.colors.gray[800]};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ${props => props.theme.transitions.default};
    
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const TimelineImage = ({ image, index, isRight }) => {
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    const isMobile = window.innerWidth <= parseInt(theme.breakpoints.md);

    return (
        <TimelineItem
            ref={ref}
            isRight={isRight}
            initial={{ opacity: 0, x: isMobile ? -30 : (isRight ? 30 : -30) }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.7, delay: index * 0.1 }}
        >
            <ImageContainer
                initial={{ scale: 0.95 }}
                animate={inView ? { scale: 1 } : {}}
                transition={{ duration: 0.5, delay: index * 0.1 + 0.2 }}
            >
                <img
                    src={image}
                    alt={`Gallery Image ${index + 1}`}
                    loading="lazy"
                />
            </ImageContainer>
        </TimelineItem>
    );
};

const HeroGallery = () => {
    const [images, setImages] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        loadMoreImages();
    }, []);

    const loadMoreImages = async () => {
        try {
            const response = await client.getEntries({
                content_type: "galleryImage",
                limit: 10,
                skip,
            });

            const imageUrls = response.items
                .map((item) => item.fields?.galleryImage?.fields?.file?.url)
                .filter(Boolean);

            setImages(prev => [...prev, ...imageUrls]);
            setSkip(prev => prev + 10);
            setHasMore(response.items.length === 10);
        } catch (error) {
            console.error("Error loading images:", error);
        }
    };

    return (
        <HeroSection>
            <HeroContent>
                <MainHeading
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.7 }}
                >
                    CAMERON LOWE
                </MainHeading>
                <SubHeading
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.7, delay: 0.2 }}
                >
                    ELITE AMATEUR GOLFER
                </SubHeading>
            </HeroContent>

            <InfiniteScroll
                dataLength={images.length}
                next={loadMoreImages}
                hasMore={hasMore}
                loader={<Loading>Loading...</Loading>}
                scrollThreshold="200px"
            >
                <TimelineContainer>
                    {images.map((image, index) => (
                        <TimelineImage
                            key={`${image}-${index}`}
                            image={image}
                            index={index}
                            isRight={index % 2 === 1}
                        />
                    ))}
                </TimelineContainer>
            </InfiniteScroll>
        </HeroSection>
    );
};

export default HeroGallery;