import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import client from "./contentful";

const TournamentsSection = styled.section`
  position: relative;
  // background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing['3xl']} 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background: ${props => props.theme.colors.gold};
    
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: ${props => props.theme.spacing.xl};
    }
  }
`;

const TournamentsHeader = styled.div`
  padding: 0 ${props => props.theme.spacing['2xl']};
  margin-bottom: ${props => props.theme.spacing['3xl']};
  position: relative;
  z-index: 1;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 ${props => props.theme.spacing.xl};
    margin-left: ${props => props.theme.spacing.xl};
  }
`;

const HeaderDate = styled(motion.div)`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.sm};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: ${props => props.theme.spacing.md};
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const HeaderTitle = styled(motion.h2)`
  font-size: clamp(3rem, 8vw, 5rem);
  line-height: 0.9;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
`;

const TournamentsContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.xl};
`;

const TournamentCard = styled(motion.div)`
  width: 45%;
  position: relative;
  background: ${props => props.theme.colors.gray[800]};
  border-radius: ${props => props.theme.spacing.sm};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: ${props => props.theme.spacing.xl};
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
  }
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    ${props => props.isRight ? `
      margin-left: 55%;
      &::before {
        right: 100%;
      }
    ` : `
      margin-right: 55%;
      &::before {
        left: 100%;
      }
    `}
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    
    &::before {
      left: -${props => props.theme.spacing.xl};
    }
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const CardInfo = styled.div`
  padding: ${props => props.theme.spacing.xl};
  
  h3 {
    color: ${props => props.theme.colors.gold};
    font-size: ${props => props.theme.typography.fontSize.xl};
    margin-bottom: ${props => props.theme.spacing.sm};
  }
  
  p {
    color: ${props => props.theme.colors.gray[200]};
    font-size: ${props => props.theme.typography.fontSize.base};
    margin-bottom: ${props => props.theme.spacing.sm};
  }
  
  a {
    display: inline-flex;
    align-items: center;
    gap: ${props => props.theme.spacing.xs};
    color: ${props => props.theme.colors.gold};
    text-decoration: none;
    font-size: ${props => props.theme.typography.fontSize.base};
    margin-top: ${props => props.theme.spacing.md};
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const TournamentCardComponent = ({ tournament, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <TournamentCard
      ref={ref}
      isRight={index % 2 === 1}
      initial={{ opacity: 0, x: index % 2 === 0 ? -30 : 30 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.7, delay: index * 0.1 }}
    >
      <CardImage src={tournament.image} alt={tournament.title} />
      <CardInfo>
        <h3>{tournament.title}</h3>
        <p>{tournament.location}</p>
        <p>{tournament.date.toDateString()}</p>
        <p>{tournament.prizefund}</p>
        <a
          href={tournament.info}
          target="_blank"
          rel="noreferrer noopener"
        >
          More Info →
        </a>
      </CardInfo>
    </TournamentCard>
  );
};

function Tournaments() {
  const [tournaments, setTournaments] = useState([]);
  const [headerRef, headerInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: "event",
        });

        const fetchedTournaments = response.items.map((item) => ({
          title: item.fields.eventName,
          location: item.fields.eventLocation,
          date: new Date(item.fields.eventDate),
          prizefund: item.fields.eventPrizefund,
          info: item.fields.eventInfo,
          image: item.fields.eventImage.fields.file.url,
        }));

        // Inside fetchData function, modify the sorting:
        const sortedTournaments = fetchedTournaments
          .sort((a, b) => b.date - a.date) // Changed from a.date - b.date to sort in descending order
          .slice(0, 3);

        setTournaments(sortedTournaments);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <TournamentsSection>
      <TournamentsHeader ref={headerRef}>
        <HeaderDate
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          Schedule
        </HeaderDate>

        <TitleWrapper>
          <HeaderTitle
            initial={{ y: "100%" }}
            animate={headerInView ? { y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Events
          </HeaderTitle>
        </TitleWrapper>
      </TournamentsHeader>

      <TournamentsContainer>
        {tournaments.map((tournament, index) => (
          <TournamentCardComponent
            key={index}
            tournament={tournament}
            index={index}
          />
        ))}
      </TournamentsContainer>
    </TournamentsSection>
  );
}

export default Tournaments;