import React, { useEffect, useState } from "react";
import client from "./contentful";
import styled from "styled-components";
import { motion } from "framer-motion";
import theme from "../Theme";
import { useInView } from "react-intersection-observer";

const AboutSection = styled.section`
  position: relative;
  // background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing['3xl']} 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background: ${props => props.theme.colors.gold};
    
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: ${props => props.theme.spacing.xl};
    }
  }
`;

const AboutHeader = styled.div`
  padding: 0 ${props => props.theme.spacing['2xl']};
  margin-bottom: ${props => props.theme.spacing['3xl']};
  position: relative;
  z-index: 1;
  overflow: hidden;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 ${props => props.theme.spacing.xl};
    margin-left: ${props => props.theme.spacing.xl};
  }
`;

const HeaderDate = styled(motion.div)`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.sm};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: ${props => props.theme.spacing.md};
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const HeaderTitle = styled(motion.h2)`
  font-size: clamp(3rem, 8vw, 5rem);
  line-height: 0.9;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
`;

const HeaderSubtitle = styled(motion.div)`
  margin-top: ${props => props.theme.spacing.lg};
  font-size: clamp(1rem, 1.5vw, 1.25rem);
  max-width: 600px;
  line-height: 1.6;
`;

const ContentContainer = styled(motion.div)`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;


const AboutText = styled.div`
  font-size: ${props => props.theme.typography.fontSize.lg};
  line-height: 1.8;
  
  p {
    margin-bottom: ${props => props.theme.spacing.lg};
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.7s ease-out forwards;
    
    &:nth-child(1) { animation-delay: 0.2s; }
    &:nth-child(2) { animation-delay: 0.4s; }
    &:nth-child(3) { animation-delay: 0.6s; }
    &:nth-child(4) { animation-delay: 0.8s; }
    &:nth-child(5) { animation-delay: 1.0s; }
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ImageContainer = styled(motion.div)`
  width: 45%;
  position: relative;
  margin-bottom: ${props => props.theme.spacing.xl};
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
  }
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 55%;
    
    &::before {
      left: 100%;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    
    &::before {
      left: -${props => props.theme.spacing.xl};
    }
  }
`;

const TextContent = styled(motion.div)`
  width: 45%;
  padding: ${props => props.theme.spacing.xl};
  margin-bottom: ${props => props.theme.spacing.xl};
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
  }
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 55%;
    
    &::before {
      right: 100%;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    
    &::before {
      left: -${props => props.theme.spacing.xl};
    }
  }
`;

const ProfileImage = styled(motion.img)`
  width: 100%;
  aspect-ratio: 3/4;
  object-fit: cover;
  border-radius: ${props => props.theme.spacing.sm};
`;

// Animation variants
const fadeUpVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.7,
      ease: [0.23, 1, 0.32, 1]
    }
  }
};

const slideUpVariant = {
  hidden: { y: "100%" },
  visible: {
    y: 0,
    transition: {
      duration: 0.7,
      ease: [0.23, 1, 0.32, 1]
    }
  }
};

const contentVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3
    }
  }
};

const imageVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
    y: 20
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.7,
      ease: [0.23, 1, 0.32, 1]
    }
  }
};

const slideInVariants = {
  hidden: (isRight) => ({
    opacity: 0,
    x: isRight ? 50 : -50
  }),
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7,
      ease: [0.23, 1, 0.32, 1]
    }
  }
};

function About() {
  const [images, setImages] = useState([]);
  const [aboutText, setAboutText] = useState("");
  const isMobile = window.innerWidth <= parseInt(theme.breakpoints.md);

  // Add intersection observer refs for each animated section
  const [headerRef, headerInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const [imageRef, imageInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const [textRef, textInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const imageResponse = await client.getEntries({
          content_type: "profileImage",
        });
        const imageUrls = imageResponse.items
          .map((item) => item.fields.profileImage.fields.file.url)
          .filter((url) => url !== null);
        setImages(imageUrls);

        const textResponse = await client.getEntries({
          content_type: "aboutParagraph",
        });
        if (textResponse.items?.length > 0) {
          const text = textResponse.items[0].fields.aboutParagraph;
          if (text) {
            const paragraphs = text.split('\n')
              .filter(p => p.trim().length > 0)
              .map(p => `<p>${p}</p>`)
              .join('');
            setAboutText(paragraphs);
          }
        }
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <AboutSection>
      <AboutHeader ref={headerRef}>
        <HeaderDate
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          EST. 2006
        </HeaderDate>

        <TitleWrapper>
          <HeaderTitle
            initial={{ y: "100%" }}
            animate={headerInView ? { y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            About Me
          </HeaderTitle>
        </TitleWrapper>

        <HeaderSubtitle
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          A journey through dedication and passion for the game of golf.
        </HeaderSubtitle>
      </AboutHeader>

      <ContentContainer>
        {images[0] && (
          <ImageContainer
            ref={imageRef}
            initial={{ opacity: 0, x: isMobile ? -30 : -30 }}
            animate={imageInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.7 }}
          >
            <ProfileImage
              src={images[0]}
              alt="Profile"
              initial={{ scale: 0.95 }}
              animate={imageInView ? { scale: 1 } : {}}
              transition={{ duration: 0.5, delay: 0.2 }}
            />
          </ImageContainer>
        )}

        <TextContent
          ref={textRef}
          initial={{ opacity: 0, x: isMobile ? -30 : 30 }}
          animate={textInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          <AboutText
            dangerouslySetInnerHTML={{ __html: aboutText || 'Loading...' }}
            style={{
              opacity: textInView ? 1 : 0,
              transform: `translateY(${textInView ? 0 : '20px'})`,
              transition: 'opacity 0.7s ease-out, transform 0.7s ease-out'
            }}
          />
        </TextContent>
      </ContentContainer>
    </AboutSection>
  );
}

export default About;