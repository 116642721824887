import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";

const useHandicapData = () => {
  const [handicapData, setHandicapData] = useState([]);

  const parseDate = (date) => {
    if (!date) return new Date(); // fallback to current date if date is missing
    if (date instanceof Date) return date;
    if (typeof date === "object" && date.toDate instanceof Function)
      return date.toDate();
    if (typeof date === "string") {
      const parsedDate = new Date(date);
      return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
    }
    return new Date(); // fallback to current date if parsing fails
  };

  useEffect(() => {
    const fetchHandicapData = async () => {
      try {
        const q = query(collection(db, "handicapData"), orderBy("date", "asc"));
        const snapshot = await getDocs(q);
        const data = snapshot.docs.map((doc) => {
          const docData = doc.data();
          // console.log("Raw document data:", docData); 
          return {
            id: doc.id,
            handicapIndex: parseFloat(docData.handicapIndex) || 0,
            scoreDifferential: parseFloat(docData.scoreDifferential) || 0,
            date: parseDate(docData.date),
          };
        });
        // console.log("Processed handicap data:", data);
        setHandicapData(data);
      } catch (error) {
        console.error("Error fetching handicap data:", error);
      }
    };

    fetchHandicapData();
  }, []);

  const addHandicapData = async (newData) => {
    try {
      const docRef = await addDoc(collection(db, "handicapData"), {
        handicapIndex: parseFloat(newData.handicapIndex) || 0,
        scoreDifferential: parseFloat(newData.scoreDifferential) || 0,
        date: new Date().toISOString(), // store as ISO string for consistency
      });
      // console.log("Document written with ID: ", docRef.id);
      return docRef;
    } catch (error) {
      // console.error("Error adding handicap data:", error);
      throw error;
    }
  };

  return { handicapData, addHandicapData };
};

export default useHandicapData;
