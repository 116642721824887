// src/hooks/useScores.js
import { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";

const useScores = () => {
  const [scores, setScores] = useState([]);

  useEffect(() => {
    const fetchScores = async () => {
      try {
        const q = query(collection(db, "scores"), orderBy("date", "desc"));
        const snapshot = await getDocs(q);
        const scoresList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setScores(scoresList);
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };

    fetchScores();
  }, []);

  const addScore = async (score) => {
    try {
      const docRef = await addDoc(collection(db, "scores"), score);
      setScores((prevScores) => [...prevScores, { id: docRef.id, ...score }]);
    } catch (error) {
      console.error("Error adding score:", error);
    }
  };

  const deleteScore = async (id) => {
    try {
      const scoreRef = doc(db, "scores", id);
      await deleteDoc(scoreRef);
      setScores((prevScores) => prevScores.filter((score) => score.id !== id));
    } catch (error) {
      console.error("Error deleting score:", error);
    }
  };

  const updateScore = async (id, updatedScore) => {
    try {
      const scoreRef = doc(db, "scores", id);
      await updateDoc(scoreRef, updatedScore);
      setScores((prevScores) =>
        prevScores.map((score) =>
          score.id === id ? { ...score, ...updatedScore } : score
        )
      );
    } catch (error) {
      console.error("Error updating score:", error);
    }
  };

  return { scores, addScore, deleteScore, updateScore };
};

export default useScores;
