import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import useHandicapData from "../hooks/useHandicapData";
import { linearRegression, linearRegressionLine } from "simple-statistics";
import { motion } from "framer-motion";

const TrackerSection = styled.section`
  position: relative;
  // background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing['3xl']} 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background: ${props => props.theme.colors.gold};
    
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: ${props => props.theme.spacing.xl};
    }
  }
`;

const TrackerHeader = styled.div`
  padding: 0 ${props => props.theme.spacing['2xl']};
  margin-bottom: ${props => props.theme.spacing['3xl']};
  position: relative;
  z-index: 1;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 ${props => props.theme.spacing.xl};
    margin-left: ${props => props.theme.spacing.xl};
  }
`;

const HeaderDate = styled(motion.div)`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.sm};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: ${props => props.theme.spacing.md};
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const HeaderTitle = styled(motion.h2)`
  font-size: clamp(3rem, 8vw, 5rem);
  line-height: 0.9;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
`;

const ContentContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const ChartContainer = styled(motion.div)`
  width: 45%;
  position: relative;
  background: ${props => props.theme.colors.gray[800]};
  padding: ${props => props.theme.spacing.xl};
  border-radius: ${props => props.theme.spacing.sm};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: ${props => props.theme.spacing.xl};
  height: 400px;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
  }
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 55%;
    &::before {
      left: 100%;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    height: 300px;
    
    &::before {
      left: -${props => props.theme.spacing.xl};
    }
  }
`;

const StatsContainer = styled(motion.div)`
  width: 45%;
  position: relative;
  margin-left: 55%;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.xl};
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
  }
`;

const StatBox = styled(motion.div)`
  background: ${props => props.theme.colors.gray[800]};
  padding: ${props => props.theme.spacing.xl};
  border-radius: ${props => props.theme.spacing.sm};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
    right: 100%;
  }
`;

const StatTitle = styled.h3`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.lg};
  margin-bottom: ${props => props.theme.spacing.sm};
`;

const StatValue = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.typography.fontSize['2xl']};
  font-weight: bold;
`;

const HandicapTracker = () => {
  const { handicapData } = useHandicapData();
  const [headerRef, headerInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const [contentRef, contentInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const chartData = useMemo(() => {
    if (handicapData.length === 0) return [];

    const sortedData = [...handicapData].sort((a, b) => a.date - b.date);

    // Calculate projection
    const firstDate = sortedData[0].date;
    const regressionData = sortedData.map((entry) => [
      (entry.date - firstDate) / (1000 * 60 * 60 * 24),
      entry.handicapIndex,
    ]);

    const regression = linearRegression(regressionData);
    const predict = linearRegressionLine(regression);

    const lastDate = sortedData[sortedData.length - 1].date;
    const projectedData = [];

    for (let i = 1; i <= 6; i++) {
      const projectedDate = new Date(lastDate);
      projectedDate.setMonth(projectedDate.getMonth() + i);
      const daysSinceStart =
        (projectedDate - firstDate) / (1000 * 60 * 60 * 24);
      const projectedHandicap = Math.max(predict(daysSinceStart), 0);

      projectedData.push({
        date: projectedDate,
        projectedHandicap: parseFloat(projectedHandicap.toFixed(1)),
      });
    }

    return [...sortedData, ...projectedData];
  }, [handicapData]);

  const getLatestHandicap = () => {
    if (handicapData.length === 0) return "N/A";
    const latestData = handicapData[handicapData.length - 1];
    return latestData.handicapIndex.toFixed(1);
  };

  const getProjectedHandicap = () => {
    if (chartData.length === 0) return "N/A";
    const projectedData = chartData.filter((d) => "projectedHandicap" in d);
    if (projectedData.length === 0) return "N/A";
    return projectedData[projectedData.length - 1].projectedHandicap.toFixed(1);
  };

  if (handicapData.length === 0) {
    return (
      <TrackerSection>
        <TrackerHeader ref={headerRef}>
          <HeaderDate
            initial={{ opacity: 0, y: 20 }}
            animate={headerInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.7 }}
          >
            Progress
          </HeaderDate>

          <TitleWrapper>
            <HeaderTitle
              initial={{ y: "100%" }}
              animate={headerInView ? { y: 0 } : {}}
              transition={{ duration: 0.7, delay: 0.2 }}
            >
              Handicap
            </HeaderTitle>
          </TitleWrapper>
        </TrackerHeader>
        <ContentContainer>
          <ChartContainer>
            <p>No handicap data available. Please add some data to see your performance.</p>
          </ChartContainer>
        </ContentContainer>
      </TrackerSection>
    );
  }

  const chartTheme = {
    grid: { stroke: props => props.theme.colors.gray[700] },
    text: { fill: props => props.theme.colors.gray[400] },
    tooltip: {
      backgroundColor: props => props.theme.colors.gray[900],
      borderColor: props => props.theme.colors.gold,
      color: props => props.theme.colors.white
    }
  };

  return (
    <TrackerSection>
      <TrackerHeader ref={headerRef}>
        <HeaderDate
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          Progress
        </HeaderDate>

        <TitleWrapper>
          <HeaderTitle
            initial={{ y: "100%" }}
            animate={headerInView ? { y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Handicap
          </HeaderTitle>
        </TitleWrapper>
      </TrackerHeader>

      <ContentContainer ref={contentRef}>
        <ChartContainer
          initial={{ opacity: 0, x: -30 }}
          animate={contentInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke={chartTheme.grid.stroke} />
              <XAxis
                dataKey="date"
                tickFormatter={formatDate}
                type="category"
                allowDuplicatedCategory={false}
                angle={-45}
                textAnchor="end"
                height={50}
                interval={"preserveStartEnd"}
                tick={{ fontSize: 10, fill: chartTheme.text.fill }}
              />
              <YAxis tick={{ fontSize: 10, fill: chartTheme.text.fill }} />
              <Tooltip
                labelFormatter={(value) => formatDate(new Date(value))}
                formatter={(value) => value.toFixed(1)}
                contentStyle={{
                  backgroundColor: chartTheme.tooltip.backgroundColor,
                  borderColor: chartTheme.tooltip.borderColor,
                  color: chartTheme.tooltip.color
                }}
              />
              <Legend wrapperStyle={{ fontSize: 10, color: chartTheme.text.fill }} />
              <Line
                type="monotone"
                dataKey="handicapIndex"
                name="Handicap Index"
                stroke={props => props.theme.colors.gold}
                strokeWidth={2}
                dot={{ r: 3 }}
              />
              <Line
                type="monotone"
                dataKey="scoreDifferential"
                name="Score Differential"
                stroke="#82ca9d"
                strokeWidth={2}
                dot={{ r: 3 }}
              />
              <Line
                type="monotone"
                dataKey="projectedHandicap"
                name="Projected Handicap"
                stroke="#ff7300"
                strokeWidth={2}
                strokeDasharray="5 5"
                dot={{ r: 3 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </ChartContainer>

        <StatsContainer>
          <StatBox
            initial={{ opacity: 0, x: 30 }}
            animate={contentInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            <StatTitle>Current Handicap Index</StatTitle>
            <StatValue>{getLatestHandicap()}</StatValue>
          </StatBox>
          <StatBox
            initial={{ opacity: 0, x: 30 }}
            animate={contentInView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.4 }}
          >
            <StatTitle>Projected Handicap (6 months)</StatTitle>
            <StatValue>{getProjectedHandicap()}</StatValue>
          </StatBox>
        </StatsContainer>
      </ContentContainer>
    </TrackerSection>
  );
};

export default HandicapTracker;