import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

const SocialsSection = styled.section`
  background: white;
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
`;

const SocialsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xl};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.lg};
  }
`;

const SocialsHeader = styled.h2`
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  font-size: clamp(1.25rem, 2vw, 1.5rem);
  font-weight: 700;
  color: #1B458F;
  margin: 0;
`;

const SocialBlock = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.lg};
  align-items: center;
`;

const SocialMediaLink = styled.a`
  color: #1B458F;
  transition: all 0.3s ease;
  
  &:hover {
    color: #C4122E;
    transform: translateY(-2px);
  }
`;

const InstagramFeedContainer = styled.div`
  background: linear-gradient(90deg, #1B458F 0%, #C4122E 100%);
  padding: ${({ theme }) => theme.spacing.xl} 0;
`;

const InstagramPost = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  height: 500px;
  margin: 0 ${({ theme }) => theme.spacing.sm};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-4px);
  }
`;

const MediaContainer = styled.div`
  height: 300px;
  overflow: hidden;
  
  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  a {
    display: block;
    height: 100%;
  }
`;

const Caption = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  color: #333;
  font-size: 0.9rem;
  line-height: 1.6;
  max-height: 200px;
  overflow-y: auto;

  /* Custom scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #C4122E transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C4122E;
    border-radius: 3px;
  }
`;

const Username = styled.div`
  font-weight: 600;
  color: #1B458F;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  
  svg {
    font-size: 16px;
  }
`;

const CarouselContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.lg};

  .slick-slide {
    padding: ${({ theme }) => theme.spacing.xs};
  }

  .slick-prev, .slick-next {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 50%;
    z-index: 1;
    
    &:before {
      color: #1B458F;
      font-size: 24px;
    }

    &:hover {
      background: #1B458F;
      
      &:before {
        color: white;
      }
    }
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }
`;

const dummyPosts = [
  {
    id: '1',
    thumbnail_url: '/api/placeholder/400/400',
    author_name: 'camlowegolf',
    title: 'Great practice session today at the range! Working on my driver consistency 🏌️‍♂️ #golf #practice',
    link: 'https://www.instagram.com/p/DCsAB8cq7GS/'
  },
  {
    id: '2',
    thumbnail_url: '/api/placeholder/400/400',
    author_name: 'camlowegolf',
    title: 'Beautiful morning for a round at the local club ⛳️ #golflife',
    link: 'https://www.instagram.com/p/example2/'
  },
  {
    id: '3',
    thumbnail_url: '/api/placeholder/400/400',
    author_name: 'camlowegolf',
    title: 'New equipment day! Can\'t wait to test these out 🎯 #newclubs',
    link: 'https://www.instagram.com/p/example3/'
  },
  {
    id: '4',
    thumbnail_url: '/api/placeholder/400/400',
    author_name: 'camlowegolf',
    title: 'Tournament prep in full swing! Ready for next week 💪 #tournament',
    link: 'https://www.instagram.com/p/example4/'
  },
  {
    id: '5',
    thumbnail_url: '/api/placeholder/400/400',
    author_name: 'camlowegolf',
    title: 'Perfect conditions for working on the short game today 🎯 #practice',
    link: 'https://www.instagram.com/p/example5/'
  }
];

const InstagramCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <SocialsSection>
        <SocialsContainer>
          <SocialsHeader>FOLLOW ME ON SOCIAL MEDIA</SocialsHeader>
          <SocialBlock>
            <SocialMediaLink
              href="https://www.instagram.com/camlowegolf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon style={{ fontSize: 32 }} />
            </SocialMediaLink>
            <SocialMediaLink
              href="https://twitter.com/cameronlowegolf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon style={{ fontSize: 32 }} />
            </SocialMediaLink>
            <SocialMediaLink
              href="https://www.youtube.com/channel/UC1QY6uVH3w5Z0t8xHJxXs1Q"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon style={{ fontSize: 32 }} />
            </SocialMediaLink>
          </SocialBlock>
        </SocialsContainer>
      </SocialsSection>

      <InstagramFeedContainer>
        <CarouselContainer>
          <Slider {...settings}>
            {dummyPosts.map((post) => (
              <InstagramPost key={post.id}>
                <MediaContainer>
                  <a href={post.link} target="_blank" rel="noopener noreferrer">
                    <img src={post.thumbnail_url} alt={post.title} />
                  </a>
                </MediaContainer>
                <Caption>
                  <Username>
                    <InstagramIcon /> {post.author_name}
                  </Username>
                  {post.title}
                </Caption>
              </InstagramPost>
            ))}
          </Slider>
        </CarouselContainer>
      </InstagramFeedContainer>
    </>
  );
};

export default InstagramCarousel;