import React, { useState, useEffect, useCallback } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import { activities } from "../data/Activities";
import { auth } from "../firebase";
import { gapi } from "gapi-script";

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 10px;
  background-color: #f0f4f8;
  color: #2d3748;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const MatrixContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DaysRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Title = styled.h1`
  color: #2d3748;
  margin-bottom: 20px;
`;

const SubTitle = styled.h2`
  color: #4a5568;
  margin-bottom: 15px;
`;

const StyledCalendar = styled(Calendar)`
  .react-calendar {
    border: none;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .react-calendar__tile--active {
    background: #4299e1;
  }

  .react-calendar__tile--now {
    background: #9ae6b4;
  }
`;

const TimeSlot = styled.div`
  border-bottom: 1px solid #e2e8f0;
  padding: 10px 0;
  min-height: 60px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f7fafc;
  }
`;

const ActivityItem = styled.div`
  background-color: ${(props) => props.color || "#fbd38d"};
  padding: 5px;
  margin: 2px 0;
  border-radius: 4px;
  cursor: grab;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  height: ${(props) => `${props.height}px`};
  top: ${(props) => `${props.top}px`};
  font-size: 0.8em;
`;

const AvailableActivities = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

const DayTitle = styled.h3`
  color: #2d3748;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

const SyncButton = styled.button`
  margin-top: 20px;
  padding: 12px 20px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #357ae8;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const DraggableActivityItem = styled(ActivityItem)`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "move")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
`;

const ActivityButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  background-color: #f0f4f8;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: #e2e8f0;
  }
`;

const AvailableActivitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  padding: 15px;
  background-color: #f8fafc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ActivityCard = styled.div`
  background-color: ${(props) => props.color || "#fbd38d"};
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const ActivityName = styled.span`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ActivityDuration = styled.span`
  font-size: 0.8em;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const DayColumn = styled.div`
  flex: 1;
  min-width: 100%;
  border: 1px solid #e2e8f0;
  padding: 15px;
  margin-bottom: 15px;
  background-color: ${(props) => (props.isCurrentDay ? "#e6fffa" : "#ffffff")};
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  @media (min-width: 769px) {
    min-width: 0;
    margin-right: 15px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const DayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
`;

const DayContent = styled.div`
  display: ${(props) => (props.isExpanded ? "block" : "none")};

  @media (min-width: 769px) {
    display: block;
  }
`;

const ExpandIcon = styled.span`
  font-size: 1.5em;
  transition: transform 0.3s ease;
  transform: ${(props) =>
    props.isExpanded ? "rotate(180deg)" : "rotate(0deg)"};

  @media (min-width: 769px) {
    display: none;
  }
`;

const ActivitySelector = ({
  isOpen,
  onClose,
  onSelectActivity,
  date,
  hour,
}) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <h3>
          Select an activity for {date} at {hour}:00
        </h3>
        {activities.map((activity) => (
          <ActivityButton
            key={activity.id}
            onClick={() => onSelectActivity(activity, date, hour)}
          >
            {activity.name}
          </ActivityButton>
        ))}
      </ModalContent>
    </ModalBackdrop>
  );
};

const getStartOfWeek = (date) => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

const TrainingCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [schedule, setSchedule] = useState({});
  const [activityCounts, setActivityCounts] = useState({});
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingGoogleAuth, setIsLoadingGoogleAuth] = useState(false);
  const [googleAuthError, setGoogleAuthError] = useState(null);
  const [isActivitySelectorOpen, setIsActivitySelectorOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [gapiInitialized, setGapiInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedDays, setExpandedDays] = useState({});

  const toggleDayExpansion = (date) => {
    setExpandedDays((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const loadGoogleAuth = async () => {
      setIsLoadingGoogleAuth(true);
      setGoogleAuthError(null);
      try {
        await new Promise((resolve, reject) => {
          gapi.load("client:auth2", resolve);
        });

        await gapi.client.init({
          clientId:
            "834484553859-ejsu5monginkg32cmavvqouaehdtgbt1.apps.googleusercontent.com",
          scope: "https://www.googleapis.com/auth/calendar.events",
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
        });

        setGapiInitialized(true);
        console.log("Google API client initialized successfully");
      } catch (error) {
        console.error("Error initializing Google API client:", error);
        setGoogleAuthError(
          `Failed to initialize Google API client: ${error.message}`
        );
      } finally {
        setIsLoadingGoogleAuth(false);
        setIsLoading(false);
      }
    };

    loadGoogleAuth();
  }, []);

  const fetchGoogleCalendarEvents = useCallback(
    async (startDate, endDate) => {
      if (!gapiInitialized) {
        console.error("Google API client not initialized");
        throw new Error("Google API client not initialized");
      }

      try {
        if (!gapi.auth2.getAuthInstance().isSignedIn.get()) {
          await gapi.auth2.getAuthInstance().signIn();
        }

        const response = await gapi.client.calendar.events.list({
          calendarId: "primary",
          timeMin: startDate.toISOString(),
          timeMax: endDate.toISOString(),
          singleEvents: true,
          orderBy: "startTime",
        });

        return response.result.items;
      } catch (error) {
        console.error("Error fetching Google Calendar events:", error);
        throw error;
      }
    },
    [gapiInitialized]
  );

  const mergeGoogleEvents = useCallback((localSchedule, googleEvents) => {
    const updatedSchedule = { ...localSchedule };

    googleEvents.forEach((event) => {
      const startDate = new Date(event.start.dateTime || event.start.date);
      const endDate = new Date(event.end.dateTime || event.end.date);
      const dateKey = startDate.toISOString().split("T")[0];
      const hour = startDate.getHours();

      if (!updatedSchedule[dateKey]) {
        updatedSchedule[dateKey] = Array(24)
          .fill()
          .map(() => []);
      }

      const existingActivity = activities.find((a) => a.name === event.summary);
      if (existingActivity) {
        updatedSchedule[dateKey][hour].push({
          ...existingActivity,
          instanceId: event.id,
          isGoogleEvent: true,
        });
      } else {
        updatedSchedule[dateKey][hour].push({
          id: "google-event",
          name: event.summary,
          type: "Google Calendar Event",
          instanceId: event.id,
          isGoogleEvent: true,
        });
      }
    });

    return updatedSchedule;
  }, []);

  const updateSchedule = useCallback(
    async (date) => {
      if (!gapiInitialized) return;

      const startOfWeek = getStartOfWeek(date);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 7);

      try {
        const googleEvents = await fetchGoogleCalendarEvents(
          startOfWeek,
          endOfWeek
        );
        const newSchedule = {};
        for (let i = 0; i < 7; i++) {
          const currentDate = new Date(startOfWeek);
          currentDate.setDate(startOfWeek.getDate() + i);
          const dayKey = currentDate.toISOString().split("T")[0];
          newSchedule[dayKey] = Array(24)
            .fill()
            .map(() => []);
        }
        const mergedSchedule = mergeGoogleEvents(newSchedule, googleEvents);
        setSchedule((prevSchedule) => ({ ...prevSchedule, ...mergedSchedule }));
      } catch (error) {
        console.error("Error updating schedule:", error);
        setError(
          error.message || "Failed to update schedule. Please try again."
        );
      }
    },
    [fetchGoogleCalendarEvents, mergeGoogleEvents, gapiInitialized]
  );

  useEffect(() => {
    if (gapiInitialized) {
      updateSchedule(selectedDate);
    }
  }, [selectedDate, gapiInitialized, updateSchedule]);

  const updateActivityCounts = useCallback(() => {
    const counts = {};
    Object.values(schedule).forEach((day) => {
      day.forEach((hour) => {
        hour.forEach((activity) => {
          counts[activity.id] = (counts[activity.id] || 0) + 1;
        });
      });
    });
    setActivityCounts(counts);
  }, [schedule]);

  useEffect(() => {
    updateActivityCounts();
  }, [schedule, updateActivityCounts]);

  const handleActivityClick = (activity) => {
    setSelectedActivity(activity);
    setIsActivitySelectorOpen(true);
  };

  const handleTimeSlotClick = (date, hour) => {
    setSelectedSlot({ date, hour });
    if (selectedActivity) {
      handleSelectActivity(selectedActivity, date, hour);
    } else {
      setIsActivitySelectorOpen(true);
    }
  };

  const handleSelectActivity = (activity, date, startHour) => {
    setSchedule((prevSchedule) => {
      const newSchedule = JSON.parse(JSON.stringify(prevSchedule));
      if (!newSchedule[date]) {
        newSchedule[date] = Array(24)
          .fill()
          .map(() => []);
      }

      const startTime = startHour;
      const endTime = startTime + activity.duration;

      const newActivity = {
        ...activity,
        instanceId: `${activity.id}-${Date.now()}`,
        startTime,
        endTime,
      };

      for (
        let hour = Math.floor(startTime);
        hour < Math.ceil(endTime);
        hour++
      ) {
        newSchedule[date][hour].push(newActivity);
      }

      return newSchedule;
    });
    setIsActivitySelectorOpen(false);
    setSelectedActivity(null);
    updateActivityCounts();
  };

  const handleRemoveActivity = (date, instanceId) => {
    setSchedule((prevSchedule) => {
      const newSchedule = JSON.parse(JSON.stringify(prevSchedule));
      for (let hour = 0; hour < 24; hour++) {
        newSchedule[date][hour] = newSchedule[date][hour].filter(
          (activity) => activity.instanceId !== instanceId
        );
      }
      return newSchedule;
    });
    updateActivityCounts();
  };

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const day = date.toLocaleDateString("en-US", { weekday: "long" });
    const shortDate = date.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
    });
    return `${day} ${shortDate}`;
  }, []);

  const isCurrentDay = useCallback((dateString) => {
    const today = new Date();
    const date = new Date(dateString);
    return date.toDateString() === today.toDateString();
  }, []);

  const syncWithGoogleCalendar = async () => {
    try {
      setError(null);

      if (!gapiInitialized) {
        throw new Error("Google API client not initialized");
      }

      if (!gapi.auth2.getAuthInstance().isSignedIn.get()) {
        await gapi.auth2.getAuthInstance().signIn();
      }

      console.log("Starting sync with Google Calendar...");

      for (const [date, hours] of Object.entries(schedule)) {
        const processedActivities = new Set();

        for (let hour = 0; hour < hours.length; hour++) {
          for (const activity of hours[hour]) {
            if (processedActivities.has(activity.instanceId)) continue;

            processedActivities.add(activity.instanceId);

            const startDateTime = new Date(
              `${date}T${Math.floor(activity.startTime)
                .toString()
                .padStart(2, "0")}:${Math.round((activity.startTime % 1) * 60)
                .toString()
                .padStart(2, "0")}:00`
            );
            const endDateTime = new Date(
              `${date}T${Math.floor(activity.endTime)
                .toString()
                .padStart(2, "0")}:${Math.round((activity.endTime % 1) * 60)
                .toString()
                .padStart(2, "0")}:00`
            );

            const eventData = {
              summary: activity.name,
              description: `${activity.name} - ${activity.type}`,
              start: {
                dateTime: startDateTime.toISOString(),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
              end: {
                dateTime: endDateTime.toISOString(),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            };

            try {
              const response = await gapi.client.calendar.events.insert({
                calendarId: "primary",
                resource: eventData,
              });

              console.log(
                `Event created successfully. Event ID: ${response.result.id}`
              );
            } catch (eventError) {
              console.error("Error creating individual event:", eventError);
            }

            await new Promise((resolve) => setTimeout(resolve, 200));
          }
        }
      }

      console.log("Sync process completed.");
      alert("Sync completed successfully!");
    } catch (error) {
      console.error("Error syncing with Google Calendar:", error);
      setError(
        error.message ||
          "Failed to sync with Google Calendar. Please try again."
      );
    }
  };

  const renderActivities = (activities, slotHeight, date) => {
    return activities.map((activity) => {
      const startOffset = (activity.startTime % 1) * slotHeight;
      const height = activity.duration * slotHeight;
      return (
        <ActivityItem
          key={activity.instanceId}
          color={activity.color}
          height={height}
          top={startOffset}
        >
          {activity.name} ({activity.startTime.toFixed(2)} -{" "}
          {activity.endTime.toFixed(2)})
          <RemoveButton
            onClick={() => handleRemoveActivity(date, activity.instanceId)}
          >
            ×
          </RemoveButton>
        </ActivityItem>
      );
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <CalendarContainer>
      <Title>Golf Training Scheduler</Title>
      <StyledCalendar onChange={setSelectedDate} value={selectedDate} />
      <AvailableActivitiesContainer>
        {activities.map((activity) => (
          <ActivityCard
            key={activity.id}
            color={activity.color}
            onClick={() => handleActivityClick(activity)}
          >
            <ActivityName>{activity.name}</ActivityName>
            <ActivityDuration>{activity.duration} hours</ActivityDuration>
          </ActivityCard>
        ))}
      </AvailableActivitiesContainer>
      <MatrixContainer>
        <SubTitle>Weekly Training Matrix</SubTitle>
        <DaysRow>
          {Object.entries(schedule).map(([date, hours]) => (
            <DayColumn key={date} isCurrentDay={isCurrentDay(date)}>
              <DayHeader onClick={() => toggleDayExpansion(date)}>
                <DayTitle>{formatDate(date)}</DayTitle>
                <ExpandIcon isExpanded={expandedDays[date]}>▼</ExpandIcon>
              </DayHeader>
              <DayContent isExpanded={expandedDays[date]}>
                {hours.map((activities, hour) => (
                  <TimeSlot
                    key={`${date}-${hour}`}
                    onClick={() => handleTimeSlotClick(date, hour)}
                  >
                    {`${hour.toString().padStart(2, "0")}:00`}
                    {renderActivities(activities, 60, date)}
                  </TimeSlot>
                ))}
              </DayContent>
            </DayColumn>
          ))}
        </DaysRow>
      </MatrixContainer>
      {gapiInitialized && user && !isLoadingGoogleAuth && !googleAuthError && (
        <SyncButton onClick={syncWithGoogleCalendar}>
          Sync with Google Calendar
        </SyncButton>
      )}
      {googleAuthError && <ErrorMessage>{googleAuthError}</ErrorMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <ActivitySelector
        isOpen={isActivitySelectorOpen}
        onClose={() => {
          setIsActivitySelectorOpen(false);
          setSelectedActivity(null);
        }}
        onSelectActivity={handleSelectActivity}
        date={selectedSlot?.date}
        hour={selectedSlot?.hour}
      />
    </CalendarContainer>
  );
};

export default TrainingCalendar;
