import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import useStats from "../hooks/useStats";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const StatsSection = styled.section`
  position: relative;
  // background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing['3xl']} 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    background: ${props => props.theme.colors.gold};
    
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      left: 50%;
      transform: translateX(-50%);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: ${props => props.theme.spacing.xl};
    }
  }
`;

const StatsHeader = styled.div`
  padding: 0 ${props => props.theme.spacing['2xl']};
  margin-bottom: ${props => props.theme.spacing['3xl']};
  position: relative;
  z-index: 1;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 ${props => props.theme.spacing.xl};
    margin-left: ${props => props.theme.spacing.xl};
  }
`;

const HeaderDate = styled(motion.div)`
  color: ${props => props.theme.colors.gold};
  font-size: ${props => props.theme.typography.fontSize.sm};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: ${props => props.theme.spacing.md};
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const HeaderTitle = styled(motion.h2)`
  font-size: clamp(3rem, 8vw, 5rem);
  line-height: 0.9;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 900;
  color: ${props => props.theme.colors.white};
`;

const StatsContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const StatCard = styled(motion.div)`
  width: 45%;
  position: relative;
  padding: ${props => props.theme.spacing.xl} 0;
  margin-bottom: ${props => props.theme.spacing.xl};
  transition: transform 0.3s ease;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: ${props => props.theme.spacing.xl};
    height: 1px;
    background: ${props => props.theme.colors.gold};
  }
  
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    ${props => props.isRight ? `
      margin-left: 55%;
      &::before {
        right: 100%;
      }
    ` : `
      margin-right: 55%;
      &::before {
        left: 100%;
      }
    `}
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - ${props => props.theme.spacing['3xl']});
    margin-left: calc(${props => props.theme.spacing.xl} + ${props => props.theme.spacing.xl});
    
    &::before {
      left: -${props => props.theme.spacing.xl};
    }
  }
`;

const StatTitle = styled.h3`
color: ${props => props.theme.colors.gold};  // Always gold
font-size: ${props => props.theme.typography.fontSize.lg};
margin-bottom: ${props => props.theme.spacing.md};
text-align: center;
text-transform: uppercase;
letter-spacing: 0.05em;
`;

const StatValue = styled.div`
color: ${props => props.theme.colors.white}; 
font-size: clamp(3rem, 5vw, 4rem);
font-weight: bold;
text-align: center;
line-height: 1;

.circular-progress {
  width: 160px;
  margin: 0 auto;
}
`;

const StatCardComponent = ({ title, value, isPercentage, index, isGoldStat }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <StatCard
      ref={ref}
      isRight={index % 2 === 1}
      initial={{ opacity: 0, x: index % 2 === 0 ? -30 : 30 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.7, delay: index * 0.1 }}
    >
      <StatTitle>{title}</StatTitle>
      <StatValue>
        {isPercentage ? (
          <div className="circular-progress">
            <CircularProgressbar
              value={value}
              text={`${value}%`}
              styles={buildStyles({
                pathColor: '#C5A572',  // Directly use gold color hex
                textColor: '#FFFFFF',  // Directly use white color hex
                trailColor: 'rgba(255, 255, 255, 0.1)',
                textSize: '22px',
                pathTransitionDuration: 0.5,
              })}
            />
          </div>
        ) : (
          value
        )}
      </StatValue>
    </StatCard>
  );
};

function Stat() {
  const stats = useStats();
  const [headerRef, headerInView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  const statsData = [
    { title: "Scoring Avg. Par 3", value: stats.scoringAveragePar3 },
    { title: "Scoring Avg. Par 4", value: stats.scoringAveragePar4 },
    { title: "Scoring Avg. Par 5", value: stats.scoringAveragePar5 },
    { title: "GIR %", value: stats.girPercentage, isPercentage: true },
    { title: "FIR %", value: stats.firPercentage, isPercentage: true },
    { title: "Avg. Drive Distance (yds)", value: stats.averageDriveDistance },
    { title: "Avg. Putts", value: stats.averagePutts },
    { title: "Avg. Proximity to Hole (ft)", value: stats.averageProximityToHole },
    { title: "Avg. Putt Length Holed (ft)", value: stats.averagePuttLengthHoled }
  ];

  return (
    <StatsSection>
      <StatsHeader ref={headerRef}>
        <HeaderDate
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.7 }}
        >
          Performance
        </HeaderDate>

        <TitleWrapper>
          <HeaderTitle
            initial={{ y: "100%" }}
            animate={headerInView ? { y: 0 } : {}}
            transition={{ duration: 0.7, delay: 0.2 }}
          >
            Statistics
          </HeaderTitle>
        </TitleWrapper>
      </StatsHeader>

      <StatsContainer>
        {statsData.map((stat, index) => (
          <StatCardComponent
            key={index}
            title={stat.title}
            value={stat.value}
            isPercentage={stat.isPercentage}
            index={index}
          />
        ))}
      </StatsContainer>
    </StatsSection>
  );
}

export default Stat;